// Import React and Component
import React, {useState, createRef} from 'react';
import {
  // Button,
  StyleSheet,
  TextInput,
  View,
  Text,
  // ScrollView,
  // Image,
  Keyboard,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native';
import { loginUser, useAuthDispatch } from '../contexts/auth';

export function LoginScreen({ navigation}) {
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errortext, setErrortext] = useState('');

  const emailInputRef = createRef();
  const passwordInputRef = createRef();

  const dispatch = useAuthDispatch();

  const handleSubmitPress = async () => {
      setErrortext('');
      if (!userEmail) {
          alert('Please fill Email');
          return;
      }
      if (!userPassword) {
          alert('Please fill Password');
          return;
      }
      setLoading(true);

      const loginResponseJson = await loginUser(dispatch, {
          email: userEmail,
          password: userPassword
      });
      if (loginResponseJson.success) {
          setLoading(false);
          navigation.navigate('Chats');
      } else {
          setErrortext('Please check your email id or password');
          setLoading(false);
      }
  }

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Text>Login</Text>
      <KeyboardAvoidingView enabled>
        <View style={styles.SectionStyle}>
          <TextInput
              style={styles.inputStyle}
              onChangeText={(UserEmail) => setUserEmail(UserEmail)}
              placeholder="Enter Email"
              placeholderTextColor="#8b9cb5"
              autoCapitalize="none"
              keyboardType="email-address"
              returnKeyType="next"
              onSubmitEditing={() =>
                  passwordInputRef.current &&
                  passwordInputRef.current.focus()
              }
              blurOnSubmit={false}
          />
        </View>
        <View style={styles.SectionStyle}>
          <TextInput
              style={styles.inputStyle}
              onChangeText={(UserPassword) => setUserPassword(UserPassword)}
              placeholder="Enter Password"
              placeholderTextColor="#8b9cb5"
              keyboardType="default"
              ref={passwordInputRef}
              onSubmitEditing={Keyboard.dismiss}
              blurOnSubmit={false}
              secureTextEntry={true}
          />
        </View>
        {errortext != '' ? (
          <Text style={styles.errorTextStyle}>
            {errortext}
          </Text>
        ) : null}
        <TouchableOpacity
            style={styles.buttonStyle}
            activeOpacity={0.5}
            onPress={handleSubmitPress}>
            <Text style={styles.buttonTextStyle}>LOGIN</Text>
        </TouchableOpacity>

        <Text
          style={styles.registerTextStyle}
          onPress={() => navigation.navigate('Register')}>
          Register an account
        </Text>
      </KeyboardAvoidingView>
    </View>
  );
}

const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: '#307ecc',
    alignContent: 'center',
  },
  SectionStyle: {
    flexDirection: 'row',
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  buttonStyle: {
    backgroundColor: '#7DE24E',
    borderWidth: 0,
    color: '#25292e',
    borderColor: '#7DE24E',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 20,
    marginBottom: 25,
  },
  buttonTextStyle: {
    color: '#25292e',
    paddingVertical: 10,
    paddingHorizontal: 20,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    color: '#25292e',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: '#dadae8',
  },
  registerTextStyle: {
    color: '#25292e',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'center',
    padding: 10,
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },
});