import React, {useState, createRef} from 'react';
import {
  // Button,
  StyleSheet,
  TextInput,
  View,
  Text,
  // ScrollView,
  // Image,
  Keyboard,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native';
import { registerUser, useAuthDispatch } from '../contexts/auth';

export function RegisterScreen({ navigation }) {
  const [newClientCode, setNewClientCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');

  const [loading, setLoading] = useState(false);
  const [errortext, setErrortext] = useState('');

  const clientCodeInputRef = createRef();
  const firstNameInputRef = createRef();
  const lastNameInputRef = createRef();
  const usernameInputRef = createRef();
  const phoneNumberInputRef = createRef();
  const emailInputRef = createRef();
  const passwordInputRef = createRef();

  const dispatch = useAuthDispatch();

  const handleSubmitPress = async () => {
      setErrortext('');
      if (!userEmail) {
          alert('Please fill Email');
          return;
      }
      if (!userPassword) {
          alert('Please fill Password');
          return;
      }
      if (!newClientCode) {
          alert('Please fill Client Code');
          return;
      }
      setLoading(true);
      var dataToSend = {
        newClientCode,
        firstName,
        lastName,
        username,
        phoneNumber,
        email: userEmail,
        password: userPassword
      };

      const registerResponseJson = await registerUser(dispatch, dataToSend);
      if (registerResponseJson.success) {
          setLoading(false);
          navigation.navigate('Chats');
      } else {
          setErrortext('Please check your email id or password');
          setLoading(false);
      }
  }
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Text>Register</Text>
      <KeyboardAvoidingView enabled>
        <View style={styles.SectionStyle}>
          <TextInput
            style={styles.inputStyle}
            onChangeText={ClientCode => setNewClientCode(ClientCode)}
            underlineColorAndroid="#f000"
            placeholder="Enter Client Code"
            placeholderTextColor="#8b9cb5"
            autoCapitalize="sentences"
            returnKeyType="next"
            onSubmitEditing={() =>
              clientCodeInputRef.current && clientCodeInputRef.current.focus()
            }
            blurOnSubmit={false}
          />
        </View>
        <View style={styles.SectionStyle}>
            <TextInput
                style={styles.inputStyle}
                onChangeText={FirstName => setFirstName(FirstName)}
                underlineColorAndroid="#f000"
                placeholder="Enter First Name"
                placeholderTextColor="#8b9cb5"
                autoCapitalize="sentences"
                returnKeyType="next"
                onSubmitEditing={() =>
                    firstNameInputRef.current && firstNameInputRef.current.focus()
                }
                blurOnSubmit={false}
            />
        </View>
        <View style={styles.SectionStyle}>
            <TextInput
                style={styles.inputStyle}
                onChangeText={LastName => setLastName(LastName)}
                underlineColorAndroid="#f000"
                placeholder="Enter Last Name"
                placeholderTextColor="#8b9cb5"
                autoCapitalize="sentences"
                returnKeyType="next"
                onSubmitEditing={() =>
                    lastNameInputRef.current && lastNameInputRef.current.focus()
                }
                blurOnSubmit={false}
            />
        </View>
        <View style={styles.SectionStyle}>
            <TextInput
                style={styles.inputStyle}
                onChangeText={Username => setUsername(Username)}
                underlineColorAndroid="#f000"
                placeholder="Enter Username"
                placeholderTextColor="#8b9cb5"
                autoCapitalize="sentences"
                returnKeyType="next"
                onSubmitEditing={() =>
                    usernameInputRef.current && usernameInputRef.current.focus()
                }
                blurOnSubmit={false}
            />
        </View>
        <View style={styles.SectionStyle}>
            <TextInput
                style={styles.inputStyle}
                onChangeText={PhoneNumber => setPhoneNumber(PhoneNumber)}
                underlineColorAndroid="#f000"
                placeholder="Enter Phone Number"
                placeholderTextColor="#8b9cb5"
                autoCapitalize="sentences"
                returnKeyType="next"
                onSubmitEditing={() =>
                    phoneNumberInputRef.current && phoneNumberInputRef.current.focus()
                }
                blurOnSubmit={false}
            />
        </View>
        <View style={styles.SectionStyle}>
            <TextInput
                style={styles.inputStyle}
                onChangeText={UserEmail => setUserEmail(UserEmail)}
                underlineColorAndroid="#f000"
                placeholder="Enter Email"
                placeholderTextColor="#8b9cb5"
                keyboardType="email-address"
                returnKeyType="next"
                onSubmitEditing={() =>
                    emailInputRef.current && emailInputRef.current.focus()
                }
                blurOnSubmit={false}
            />
        </View>
        <View style={styles.SectionStyle}>
            <TextInput
                style={styles.inputStyle}
                onChangeText={UserPassword => setUserPassword(UserPassword)}
                underlineColorAndroid="#f000"
                placeholder="Enter Password"
                placeholderTextColor="#8b9cb5"
                keyboardType="default"
                ref={passwordInputRef}
                onSubmitEditing={Keyboard.dismiss}
                blurOnSubmit={false}
                secureTextEntry={true}
            />
        </View>
        {errortext != '' ? (
          <Text style={styles.errorTextStyle}>
            {errortext}
          </Text>
        ) : null}
        <TouchableOpacity
          style={styles.buttonStyle}
          activeOpacity={0.5}
          onPress={handleSubmitPress}>
          <Text style={styles.buttonTextStyle}>REGISTER</Text>
        </TouchableOpacity>
        <Text
          style={styles.registerTextStyle}
          onPress={() => navigation.navigate('Login')}
        >
            Already Registered? Click here to login
        </Text>
      </KeyboardAvoidingView>
    </View>
  );
}

const styles = StyleSheet.create({
  SectionStyle: {
    flexDirection: 'row',
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  buttonStyle: {
    backgroundColor: '#7DE24E',
    borderWidth: 0,
    color: '#25292e',
    borderColor: '#7DE24E',
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 20,
    marginBottom: 20,
  },
  buttonTextStyle: {
    color: '#25292e',
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    color: '#25292e',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: '#dadae8',
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
  },
  successTextStyle: {
    color: '#25292e',
    textAlign: 'center',
    fontSize: 18,
    padding: 30,
  },
});