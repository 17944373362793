import { StreamChat } from 'stream-chat';
import { streamChatEnv } from '../../constants';

const apiKey = 's7skev2gpnk9';

const client = StreamChat.getInstance(apiKey);

export async function connectUserAndReturnChannel({
    userID,
    streamToken,
    chatID,
}) {
    client.connectUser(
        { id: `${streamChatEnv}-user-${userID}` },
        async () => {
            return streamToken;
        }
    );
    const channel = client.channel('messaging', `${streamChatEnv}-channel-${chatID}`);
    return channel;
}