import {
  // useContext,
  useState,
} from 'react';
// import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import {
  // Button,
  StyleSheet,
  // Text,
  // View,
} from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ChatScreen } from './screens/ChatScreen';
import { LoginScreen } from './screens/Login';
import { RegisterScreen } from './screens/Register';
import { ChatList } from './screens/ChatList';
import { AuthProvider, useAuthState } from './contexts/auth';

function AuthStack() {
  const { token, streamToken } = useAuthState();
  const isSignedIn = token && streamToken;
  return (
    <Stack.Navigator initialRouteName={isSignedIn ? 'Chats' : 'Login'}>
      {isSignedIn ? (
        <>
          <Stack.Screen name="Chats" component={ChatList} />
          <Stack.Screen
            name="Chat"
            component={ChatScreen}
            options={{ title: 'Chat' }}
          />
        </>
      ) : (
        <>
          <Stack.Screen name="Login" component={LoginScreen} />
          <Stack.Screen name="Register" component={RegisterScreen} />
        </>
      )}
    </Stack.Navigator>
  )
}

const Stack = createNativeStackNavigator();

export default function App() {
  const [authState, setAuthState] = useState({
    id:"",
    username:"",
    isSignedIn:false,
  })
  return (
    <SafeAreaProvider>
      <NavigationContainer>
        <AuthProvider>
          <AuthStack />
        </AuthProvider>
      </NavigationContainer>
    </SafeAreaProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#25292e',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
