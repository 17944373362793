import React, { useState, useCallback, useEffect } from 'react';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';
// import { GiftedChat } from 'react-native-gifted-chat';
import { GiftedChat } from '../components/chat/GiftedChat';
import { connectUserAndReturnChannel } from '../modules/StreamChat/StreamChat';
import { useAuthState } from '../contexts/auth';
import { heyCoachApiUrl, streamChatEnv } from '../constants';

async function asyncForEach (array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

async function sendMessage({ messages, chatID, authToken }) {
  const messagesFromToday = getMessagesFromToday(messages);

  const response = await fetch(`${heyCoachApiUrl}/api/v1/message`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
    },
    body: JSON.stringify({
      chat_id: chatID,
      messages: messagesFromToday.reverse(),
    }),
  });
  const responseJson = await response.json();
  return responseJson;
}

function getMessage({ id, user, text, created_at }) {
  const data = {
    _id: id,
    text: text,
    createdAt: new Date(created_at),
    user: {
      _id: user.id,
      name: user.name,
      avatar: user.image
    }
  }

  return {
    message: data
  }
}

function getMessagesFromToday(messages) {
  const mostRecentMessagesLast = [...messages].sort((a, b) => a.createdAt - b.createdAt);

  // get all messages from today
  const today = new Date();

  // don't filter through all the messages, use a for loop to stop when we get to yesterday's messages
  const messagesFromToday = [];
  for (let i = mostRecentMessagesLast.length - 1; i >= 0; i--) {
    const message = mostRecentMessagesLast[i];
    const messageDate = new Date(message.createdAt);
    if (messageDate.getDate() === today.getDate()) {
      messagesFromToday.push({
        sender_id: parseInt(message.user._id[message.user._id.length-1]),
        text: message.text,
        timestamp: message.createdAt,
      });
    } else {
      break;
    }
  }

  return messagesFromToday;
}

export function ChatScreen({ route }) {
  const { chat, isCoach } = route.params;
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const stringChatID = `${chat.id}`;

  const {
    streamToken,
    token,
    currentUserID,
  } = useAuthState();

  const stringCurrentUserID = `${currentUserID}`;

  useEffect(() => {
    const watchChannel = async () => {
      const channel = await connectUserAndReturnChannel({
        userID: stringCurrentUserID,
        streamToken,
        chatID: stringChatID,
      });
      const channelState = await channel.watch();

      await asyncForEach(channelState.messages, async (msg) => {
        const { message } = getMessage(msg);
        setMessages(previousMessages => GiftedChat.append(previousMessages, message));
      });

      channel.on('message.new', event => {
        const { user } = event.message;
        if (user.id !== `${streamChatEnv}-user-${stringCurrentUserID}`) {
          // this causes a problem when the user sends a message from a different device
          // the message is not seen in a second device the user is logged in on.
          const { message } = getMessage(event.message);
          setMessages(previousMessages => GiftedChat.append(previousMessages, message))
        }
      })
    }

    watchChannel();
  }, [])

  const onSend = useCallback(async (_messages = [], messages = []) => {
    setMessages(previousMessages => GiftedChat.append(previousMessages, _messages));

    const { success } = await sendMessage({
      messages: messages.concat(_messages),
      chatID: stringChatID,
      authToken: token,
    });
    if (!success) {
      console.log('error sending message');
      // remove message from chat
    }
  }, [])

  async function onPress(event) {
    const messagesFromToday = getMessagesFromToday(messages);

    const response = await fetch(`${heyCoachApiUrl}/api/v1/suggested-chat-response`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        chat_id: chat.id,
        messages: messagesFromToday.reverse(),
      }),
    });
    const responseJson = await response.json();

    const { suggested_chat_response: suggestedChatResponse } = responseJson;

    if (suggestedChatResponse) {
      setInputText(suggestedChatResponse);
    }

  }

  return (
    <View>
      <GiftedChat
        messages={messages}
        text={inputText}
        allowSpaceBelowInput={!!isCoach}
        onInputTextChanged={text => setInputText(text)}
        onSend={_messages => onSend(_messages, messages)}
        user={{
          _id: `${streamChatEnv}-user-${stringCurrentUserID}`,
        }}
      />
      {isCoach &&
        <TouchableOpacity style={styles.button} onPress={onPress}>
          <Text style={{ color: '#0084FF' }}>Suggested Response</Text>
        </TouchableOpacity>
      }
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#DDDDDD',
    padding: 10,
  }
});