import { useEffect, useState } from 'react';
// import { Button, StyleSheet, Text, View } from 'react-native';
import { ListItem, Avatar } from '@rneui/themed';
import { useAuthState } from '../contexts/auth';
import { heyCoachApiUrl } from '../constants';

export function ChatList({ navigation }) {
    const [chats, setChats] = useState([]);
    const { token } = useAuthState();

    useEffect(() => {
        const getChats = async () => {
            const response = await fetch(`${heyCoachApiUrl}/api/v1/chats`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`

                },
            });
            const responseJson = await response.json();
            const { chats = [] } = responseJson;
            setChats(chats);
        }
        getChats();
    }, []);

    function getOtherParticipantName(chat) {
      const chatParticipant = chat.userRole === 'coach' ? chat : chat.coach;
      const { user: { first_name, last_name } } = chatParticipant;
      return `${first_name} ${last_name}`;
    }
    
    return (
      <>
        {chats.map((chat) => (
          <ListItem key={chat.id} bottomDivider onPress={() => navigation.navigate('Chat', { chat, isCoach: chat.userRole === 'coach' })}>
            <Avatar rounded title="A" containerStyle={{ backgroundColor: 'grey' }} />
            <ListItem.Content>
              <ListItem.Title>{getOtherParticipantName(chat)}</ListItem.Title>
              {chat.last_message && <ListItem.Subtitle>{chat.last_message}</ListItem.Subtitle>}
            </ListItem.Content>
          </ListItem>
        ))}
      </>
    );
  }

{/* <ListItem bottomDivider>
          <Avatar rounded title="A" containerStyle={{ backgroundColor: 'grey' }} />
          <ListItem.Content>
            <ListItem.Title>John Doe</ListItem.Title>
            <ListItem.Subtitle>President</ListItem.Subtitle>
          </ListItem.Content>
        </ListItem>
        <ListItem bottomDivider>
        <Avatar rounded title="A" containerStyle={{ backgroundColor: 'grey' }} />
          <ListItem.Content>
            <ListItem.Title>Alba King</ListItem.Title>
            <ListItem.Subtitle>Vice President</ListItem.Subtitle>
          </ListItem.Content>
        </ListItem>
        <ListItem>
          <Avatar rounded title="A" containerStyle={{ backgroundColor: 'grey' }} />
          <ListItem.Content>
            <ListItem.Title>Adam Eva</ListItem.Title>
            <ListItem.Subtitle>Vice Chairman</ListItem.Subtitle>
          </ListItem.Content>
        </ListItem> */}

{/* <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
  <Text>Chats</Text>
  <Button
    title="Chat"
    onPress={() => navigation.navigate('Chat')}
  />
</View> */}